<template>
  <div>
    <div v-if="!items.length > 0" class="mt-3 content-tabs-cards">
      <v-tabs v-model="tab" background-color="transparent" color="#2759A2" align-with-title center-active show-arrows
        :touchless="true">
        <v-tabs-slider class="px-slider"></v-tabs-slider>
        <v-tab v-for="(item, index) in tabs" :key="index">
          {{ item.text }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" :touchless="true">
        <v-tab-item v-for="(item, index) in tabs" :key="index">
          <KeepAlive>
            <component :tab="stab" :is="item.component" :items="items" :oExpense="item.oExpense" />
          </KeepAlive>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <empty-content-component v-else text="Aún no hay información para mostrar." height="450" />
    <!-- loader -->
    <v-overlay class="content-overlay-global" :value="bLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
      <span>Obteniendo información, por favor espere...</span>
    </v-overlay>
  </div>
</template>

<script>
import LayoutContentPreInvoice from "./ContentPreInvoice/Content.vue";
import LayoutContentPaid from "./ContentPaid/Content.vue";

export default {
  name: "LayoutClientsContent",
  props: {
    arrDates: String,
    // oExpense: Object,
    // oPayments: Object,
  },
  data() {
    return {
      bLoading: false,
      items: {},
      tab: 0,
      tabs: [
      ],
      stab: null,
      emptyData: "Sin información.",
      oPayments: {},
      oExpense: {},
    };
  },
  beforeMount() {
    // this.getData();
    this.getExpense();
    this.fillTab();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.tableHeight = window.innerHeight - 315;
      if (window.innerWidth > 960) {
        this.activeCard = false;
      } else {
        this.activeCard = true;
      }
    },
    fillTab() {
      this.tabs = [
        { text: "Prefactura", component: "LayoutContentPreInvoice", oExpense: this.oExpense },
        { text: "Pagos", component: "LayoutContentPaid", oExpense: this.oPayments },
      ]
    },
    getExpense() {
      this.bLoading = true;
      this.oExpense = {}

      // this.bLoading = true;
      // this.$route.params.id = "e9ede20c-07ec-4a62-a6fd-fa033056d83e"
      DB.get(`${URI}/enterprises/${this.$store.state.sEnterpriseId}/advance-invoice`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          tPeriodDate: this.arrDates
        },
      })
        .then((response) => {
          this.oExpense = response.data.results;
          this.$store.commit("refresher", false);
          this.getPayments();
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getPayments() {
      this.oPayments = {}
      // this.$route.params.id = "e9ede20c-07ec-4a62-a6fd-fa033056d83e"
      DB.get(`${URI}/enterprises/${this.$store.state.sEnterpriseId}/advance-invoice/payments`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {
          tPeriodDate: this.arrDates
        },
      })
        .then((response) => {
          this.oPayments = response.data.results;
          this.$store.commit("refresher", false);
          this.bLoading = false;
        })
        .catch((error) => {
          this.bLoading = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    getData: function () {
      // this.bLoading = true;

      // DB.get(`${URI}/logs`, {
      //   headers: {
      //     Authorization: `Bearer ${this.$store.state.sToken}`,
      //   },
      //   params: {
      //     sDate: this.sDate,
      //   },
      // })
      //   .then((response) => {
      //     this.bLoading = false;
      //     this.items = response.data.results.map((e) => {
      //       return {
      //         ...e,
      //         sActionRegistred: e.sLogType,
      //         sNameAudit: e.oUser.sFullName,
      //         sRole: e.oUser.sUserType,
      //         sMessage: e.sMessage,

      //       }
      //     })
      //     this.numPages = response.data.iNumPages;
      //     this.iPageNumber = response.data.iNumPages;
      //     this.totalItems = response.data.iTotal;
      //     this.$store.commit("refresher", false);
      //   })
      //   .catch((error) => {
      //     this.bLoading = false;
      //     this.mixError(error.response.data.message, error.response.status);
      //   });
    },
  },
  computed: {
    refresh: function () {
      return this.$store.state.refresh;
    },
  },
  watch: {
    refresh: function () {
      if (this.tab == 0) {
        this.getExpense();
      } else {
        this.getPayments();
      }
    },
    tab() {
      this.stab = this.tab;
      if (this.tab == 0) {
        this.getExpense();
      } else {
        this.getPayments();
      }
    },
    arrDates: function () {
      this.fillTab();
      if (this.tab == 0) {
        this.getExpense();
      } else {
        this.getPayments();
      }
    },
    oExpense: function () {
      this.fillTab();
    },
    oPayments: function () {
      this.fillTab();
    }
  },
  components: {
    LayoutContentPreInvoice,
    LayoutContentPaid,
  },
};
</script>

<style scoped>
.card-inactive {
  border: 1px solid #e33f3f;
}

.card-active {
  border: 1px solid #2759a2;
}

.txt-item-table {
  font-size: 12px !important;
  align-items: center !important;

  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-link {
  font-size: 12px !important;
  align-items: center !important;

  letter-spacing: 0px;
  color: #0971fa;
  opacity: 1;
}

.text-icon-launch {
  color: #0971fa;
  /* margin-top: -5px; */
  font-size: 10px;
  cursor: pointer;
}

.txt-item-table-role {
  font-size: 9px;
  color: #A1ACC3
}

.active-platform {
  background: #ddebff 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #2759a2;
  padding: 5px;
}

.inactive-platform {
  background: #ffc6c6 0% 0% no-repeat padding-box;
  border-radius: 18px;
  opacity: 1;
  color: #ffc6c6;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0px;
  color: #e33f3f;
  padding: 5px;
}

.active-platform:hover,
.inactive-platform:hover {
  cursor: pointer;
}

/* styles cards */
.txt-item-table-card-title {
  font-size: 16px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  font-weight: 600;
}

.txt-item-table-card-item {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.txt-item-table-card-item-empty {
  font-size: 12px !important;
  letter-spacing: 0px;
  color: #818181;
  opacity: 1;
  font-style: italic;
}

.content-status-mobile-card {
  width: 40px;
  position: absolute;
  right: 30px;
  top: 10px;
}

.content-message-audit-mobile {
  height: auto;
  max-height: 25px;
  width: 100%;
  max-width: 50px;
  min-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.content-message-audit-mobile-extend {
  max-height: 400px !important;
  white-space: inherit !important;
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  -webkit-transition: all .5s ease-in-out;
  -moz-transition: all .5s ease-in-out;
  -ms-transition: all .5s ease-in-out;
  -o-transition: all .5s ease-in-out;
  transition: all .5s ease-in-out;
}

.icon-arrow-card {
  -webkit-transition: all .3s ease-in-out;
  -moz-transition: all .3s ease-in-out;
  -ms-transition: all .3s ease-in-out;
  -o-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}

.icon-arrow-card-extend {
  transform: rotate(3.142rad);
}
</style>