<template>
  <div class="content-detail-global">
    <div>
      <div class="content-general-information-and-subtotal">
        <div class="content-general-information">
          <content-consumptions :aConsumption="aConsumption" :tFinalDate="tFinalDate"  />

          <content-additional-products :aAdditionalProduct="aAdditionalProduct" />

          <content-discounts :aDiscounts="aDiscounts" />
        </div>

        <div class="content-subtotal">
          <content-subtotales :oSubtotal="oSubtotal" :tFinalDate="tFinalDate" />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import ContentConsumptions from './ContentConsumptions.vue';
import ContentAdditionalProducts from './ContentAdditionalProducts.vue';
import ContentDiscounts from './ContentDiscounts.vue';
import ContentSubtotales from './ContentSubtotales.vue';

export default {
  props: {
    oItem: Object,
    oExpense: Object,
  },
  data() {
    return {
      bLoading: false,
      sPrebillingId: "",
      oGeneralInformation: {},
      oBillingInformation: {},
      oSubtotal: {},
      aConsumption: [],
      aAdditionalProduct: [],
      aDiscounts: [],
      bMobile: false,
      bDialogConfirmGlobal: false,
      oItemDialogSendEmail: {}
    };
  },
  beforeMount() {
    this.sPrebillingId = this.$route.params.id;
    this.fullDataItem(this.oExpense);
    // this.getPrebillingDetail();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      if (window.innerWidth > 960) {
        this.bMobile = false;
      }
      else {
        this.bMobile = true;
      }
    },
    fullDataItem(oExpense) {
      this.aConsumption = oExpense.aItems;
      this.aAdditionalProduct = oExpense.aProducts;
      this.aDiscounts = oExpense.aDiscounts;
      this.oSubtotal = oExpense.oTotals;
      this.tFinalDate = oExpense.tFinalDate
    },
    discardChange() {
      this.fullData();
    },
    back() {
      this.$router.push({ name: "prebilling" }).catch((e) => { });
    },
    sendEmail() {
      let oItem = {
        sTitleDialog: "Enviar prefactura por correo",
        sQuestion: `<span>¿Estas seguro de enviar la prefactura ${this.oGeneralInformation.sFolio}?</span><br/><span>Esta acción enviará un correo a ${this.oBillingInformation.sEmail}</span>`,
        sDoubleConfirmation: true,
        sConfirmation: `Acepto la responsabilidad y deseo proseguir con el envío de la prefactura: ${this.oGeneralInformation.sFolio}`,
        sApi: "",
        sNameBtnAction: "Enviar",
      }
      this.oItemDialogSendEmail = oItem;
      this.bDialogConfirmGlobal = !this.bDialogConfirmGlobal
    }
  },
  watch: {
    oExpense() {
      this.fullDataItem(this.oExpense);

    }
  },
  components: {
    ContentConsumptions,
    ContentAdditionalProducts,
    ContentDiscounts,
    ContentSubtotales
  }
}
</script>

<style scoped>
.content-general-information-and-subtotal {
  display: flex;
  width: 100%;
}

.content-general-information {
  width: 100%;
  margin-right: 15px;
}

/*#region GLOBAL CONTENT BTN */
.content-btns-global {
  display: flex;
  justify-content: flex-end;
  margin-top: 15px;
}

.content-btn-edit {
  width: 150px;
}


/*#endregion GLOBAL CONTENT BTN */

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-general-information-and-subtotal {
    display: block;
    width: 100%;
  }

  .content-general-information {
    width: 100%;
    margin-right: 15px;
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-general-information-and-subtotal {
    display: block;
    width: 100%;
  }

  .content-general-information {
    width: 100%;
    margin-right: 15px;
  }

  /*#region GLOBAL CONTENT BTN */
  .content-btns-global {
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
  }

  .content-btn-edit {
    width: 100%;
  }


  /*#endregion GLOBAL CONTENT BTN */
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>