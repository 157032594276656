import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCurrencyInput from "vue-currency-input";
import axios from "axios";
import lodash from "lodash";
import moment from "moment";
import { mixs } from "@/mixins";
import * as VueGoogleMaps from "vue2-google-maps";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "@/modules";
import VueApexCharts from "vue-apexcharts";
import VueMapbox from "vue-mapbox";
import Mapbox from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

Vue.use(VueMapbox, { mapboxgl: Mapbox });
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;

// import of mixins for the alert call
Vue.mixin(mixs);

// use of alerts throughout the project
Vue.use(Toast, {
  transition: "Vue-Toastification__bounce",
  maxToasts: 20,
  newestOnTop: true,
});

// import currency
Vue.use(VueCurrencyInput, {
  locale: "es",
  currency: "MXN",
  valueAsInteger: false,
  distractionFree: false,
  precision: 2,
  autoDecimalMode: true,
  allowNegative: false,
});

// Google Maps Integration
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyArzew42XOo4jWF_0fWBPa-lEhpFn6-OS0",
    // key: 'AIzaSyAKC6VvI7gKOK5mKruoRxmhz-nWb7AFu6w',
    libraries: "places",
  },
});

// import axios
window.DB = axios;

// import lodas
window.lodash = lodash;

// import moment
window.MOMENT = moment;

// initailize URI for global use
window.URI = "https://api.lineasmoviles.com/qa/api/v1";
window.URL_KITE = "https://jievyrijje.execute-api.us-east-1.amazonaws.com/qa";

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
