<template>
    <div>
        <div class="content-title-section title-section-dashboard mb-2">Top 30 tarjetas SIM</div>
        <div class="content-dashboard content-empty-top-sim-cards-dashboard txt-empty-global" :class="bChangeSize?'h-1060px':''" v-if="aSimCardsTop.length === 0">
            No hay información
        </div>
        <div v-else class="content-dashboard content-sim-cards-top" :class="bChangeSize?'h-1060px':''">
            <v-row>
                <v-col cols="12" v-for="(item,i) in aSimCardsTop" :key="i">
                    <div class="content-dashboard-card">
                        <div class="content-dashboard-left">
                            <span class="txt-result-number-dashboard-card">
                                {{i + 1}}
                            </span>
                        </div>
                        <div class="divider-dashboard-card" />
                        <div class="content-dashboard-right">
                            <div class="content-title-result-dashboard font-bold">
                                <span class="txt-result-dashboard-card">{{item.sID}}</span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <span class="txt-title-dashboard-card">{{item.sName}}</span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <span class="txt-title-dashboard-card">{{item.sPlan}}</span>
                            </div>
                            <div class="content-title-result-dashboard">
                                <v-progress-linear height="15" rounded :color="getColorGlobal(item.dCurrent,item.dLimit)"  :value="item.dProcessBar">
                                    <template v-slot:default="{ value }">
                                        <span class="txt-value-progress-linear-dashboard-card">{{ item.sCurrentAndLimit }}</span>
                                    </template>
                                </v-progress-linear>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        aSimCardsTop: Array
    },
    data() {
        return {
            // aSimCardsTop: [
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "2",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "3",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            //     {
            //         iIndex: "1",
            //         sID: "233534534523432423",
            //         sName: "Taxis del Sur",
            //         sPlan: "10MB NACIONAL",
            //         dProcessBar: 20
            //     },
            // ],
            iHeight: 0,
            bChangeSize: false
        }
    },
    updated() {
        this.onResize()
    },
    beforeDestroy() {
        if (typeof window === 'undefined') return
        window.removeEventListener('resize', this.onResize, { passive: true })
    },
    mounted() {
        this.onResize()
        window.addEventListener('resize', this.onResize, { passive: true })
    },
    methods: {
        onResize() {
            this.iHeight = window.innerHeight - this.iContentPromotionsDashboard - 175;
            if (this.bMenu) {
                if (window.innerWidth > 959.99 && window.innerWidth < 1421) {
                    this.bChangeSize = true;
                } else {
                    this.bChangeSize = false;
                }
            } else {
                if (window.innerWidth > 959.99 && window.innerWidth < 1221) {
                    this.bChangeSize = true;

                } else {
                    this.bChangeSize = false;
                }
            }
        },
    },
    computed: {
        iContentPromotionsDashboard() {
            return this.$store.state.iContentPromotionsDashboard;
        },
        iContentPlansDashboard() {
            return this.$store.state.iContentPlansDashboard;
        },
        bMenu() {
            return this.$store.state.bMenu
        }
    },
    watch: {
        bMenu() {
            this.onResize()
        }
    }
}
</script>
<style>
.content-sim-cards-top {
    overflow: auto;
    height: 622px;
}

.h-1060px {
    height: 1020px !important;

}

.txt-value-progress-linear-dashboard-card{
    font-size: 11px !important;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
    .demoColorResponsive {
        background-color: rgb(190, 136, 226);
    }

    .content-sim-cards-top {
        overflow: auto;
        height: 100%;
    }

}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
    .demoColorResponsive {
        background-color: rgb(136, 226, 151);
    }

    .content-sim-cards-top {
        overflow: auto;
        height: 100%;
    }

}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
    .demoColorResponsive {
        background-color: rgb(201, 122, 83);
    }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(204, 198, 109);
    }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
    .demoColorResponsive {
        background-color: rgb(113, 199, 201);
    }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
<style scoped>
.content-empty-top-sim-cards-dashboard {
    height: 622px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>


